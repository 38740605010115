.box {
  position: relative;
  width: 600px;
  height: 200px;
  border-bottom: 2px solid #555;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box label {
  position: relative;
  width: 100%;
  height: 33.333%;
  border: 2px solid #555;
  border-bottom: none;
}

.box label input {
  position: relative;
  appearance: none;
  z-index: 10;
}

.box label input:checked ~ i {
  animation-play-state: paused;
}

.box label i {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.box label:nth-child(1) i {
  background: url('../../assets/img/01.jpg');
  animation: animate 0.5s steps(3) infinite;
}

.box label:nth-child(2) i {
  background: url('../../assets/img/02.jpg');
  animation: animate 0.7s steps(3) infinite;
}

.box label:nth-child(3) i {
  background: url('../../assets/img/03.jpg');
  animation: animate 0.9s steps(3) infinite;
}

.checkbox {
  color: red;
}


.reset {
  margin-top: 40px;
  border: none;
  font-size: 1.25em;
  padding: 10px 25px;
  background: #6d59a8;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.reset:hover {
  transform: scale(1.1);
}

.reset:active {
  transform: scale(1);
}

.title {
  text-align: center;
  font-size: 3em;
  margin-bottom: 20px;
}

.subtitle {
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 700px) {
  .box {
    width: 400px;
  }
}

@media (max-width: 510px) {
  .box {
    width: 200px;
  }
}

@keyframes animate {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: 600px;
  }
}